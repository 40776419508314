/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Chip } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'
import useDarkMode from 'use-dark-mode'
import ChipClasses from '../utils/chipProp'

import Phone from '../assets/svg/phone.svg'

const System = () => {
    const { t } = useTranslation('system')
    const darkMode = useDarkMode(false)

    return (
        <div id="system" className="systemPage">
            <div className="systemTitles">
                <Chip label={t('ourSystem')} {...ChipClasses} />
                <h2 className="pageTitle">{t('mission')}</h2>
                <p className="missionDescription">{t('description')}</p>
            </div>
            {darkMode.value ? (
                <StaticImage
                    alt={t('overview')}
                    className="overviewImage"
                    src="../assets/images/overview_dark.png"
                />
            ) : (
                <StaticImage
                    alt={t('overview')}
                    className="overviewImage"
                    src="../assets/images/overview.png"
                />
            )}
            <div>
                <div className="card cameraCard">
                    <StaticImage
                        alt={t('cameraTitle')}
                        className="cardImage"
                        src="../assets/images/camera.png"
                    />
                    <div className="cardInfo">
                        <Chip label={t('camera')} {...ChipClasses} />
                        <h3 className="cardTitle">{t('cameraTitle')}</h3>
                        <p className="cardDescription">{t('cameraDescription')}</p>
                    </div>
                </div>
                <div className="cardGrid">
                    <div className="card">
                        <StaticImage
                            alt={t('boxTitle')}
                            className="cardImage"
                            src="../assets/images/box.png"
                        />
                        <div className="cardInfo">
                            <Chip label={t('box')} {...ChipClasses} />
                            <h3 className="cardTitle">{t('boxTitle')}</h3>
                            <p className="cardDescription">{t('boxDescription')}</p>
                        </div>
                    </div>
                    <div className="card">
                        <StaticImage
                            alt={t('alarmTitle')}
                            className="cardImage"
                            src="../assets/images/alarm.png"
                        />
                        <div className="cardInfo">
                            <Chip label={t('alarm')} {...ChipClasses} />
                            <h3 className="cardTitle">{t('alarmTitle')}</h3>
                            <p className="cardDescription">{t('alarmDescription')}</p>
                        </div>
                    </div>
                </div>
                <div className="card mobileCard">
                    <div className="cardInfo">
                        <Chip label={t('mobile')} {...ChipClasses} />
                        <h3 className="cardTitle">{t('mobileTitle')}</h3>
                        <p className="cardDescription">{t('mobileDescription')}</p>
                    </div>
                    <Phone className="phone" />
                </div>
            </div>
        </div>
    )
}
export default System
